import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ButtonGroup } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {isStringEmpty} from '../utils/isStringEmpty.js';
import {isEmailValid} from '../utils/isEmailValid.js';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Api from '../utils/request/http.js';
import Cookies from 'js-cookie';
import config from '../config.js';
import ReCAPTCHA from 'react-google-recaptcha';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import Loader from '../components/Loader.js';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import '../App.css'


const ReCAPTCHA_SITE_KEY = config.recaptcha_key

const defaultTheme = createTheme();

const GrayButton = styled(Button)({
  color: '#ccc',
  borderTopColor: '#ccc',
  borderRightColor: '#ccc',
  borderBottomColor: '#ccc',
});

export default function SignIn({setShowSidebar}) {

  const [showLoader, setShowLoader] = React.useState(false);
  
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [captchaValue, setCaptchaValue] = React.useState(null);

  const recaptchaRef = React.useRef();

  const [errors, setErrors] = React.useState({});

  const [showPassword, setShowPassword] = React.useState(false);

  const clientId = config.google_auth_key;

  const facebookAppId = config.facebook_app_id;

  const onGoogleSuccess = async (res) => {
    // const data = new FormData();
    // const googleUser = jwtDecode(res.credential)
    // console.log(googleUser)
    
    // data.append('firstName', googleUser.given_name);
    // data.append('lastName', googleUser.family_name);
    // data.append('email', googleUser.email);
    // data.append('googleId', googleUser.googleId);

    setShowLoader(true);
    Api.post('/customer/registerWithGoogle', {jwt_token: res.credential})
        .then((response) => {
          console.log('User created:', response);
          setShowLoader(false);
          if (response.code !== 1000) {
            enqueueSnackbar(response.msg, { variant: 'error' });
          } else {
            enqueueSnackbar('Login in successfully', { variant: 'success' });
            Cookies.set('acc_token', response.data.access_token);
            let repairStep = JSON.parse(localStorage.getItem('repair_step'));
            if (repairStep) {
              window.location.href = '/Repair';
            } else {
              window.location.href = '/';
            }
          }
        })
        .catch((error) => {
          setShowLoader(false);
          console.error('Error:', error);
          enqueueSnackbar('Network error', { variant: 'error' });
        });
  };

  const onGoogleFailure = (err) => {
    console.log('failed:', err);
    // enqueueSnackbar(err.message,  {variant: 'error'})
  };

  const responseFacebook = (response) => {
    console.log('Facebook user info:', response);    
    const { name, email, userID } = response;
    if (name && email && userID) {
       
   
        console.log('Name:', name);
        console.log('Email:', email);
        console.log('Facebook User ID:', userID);

        const data = new FormData();
        data.append('firstName', name);
        data.append('email', email);
        data.append('facebookId', userID);

        setShowLoader(true);
        Api.post('/customer/registerWithFacebook', data)
            .then((response) => {
                console.log('User created:', response);
                setShowLoader(false);
                if (response.code !== 1000) {
                    enqueueSnackbar(response.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar('Login in successfully', { variant: 'success' });
                    Cookies.set('acc_token', response.data.access_token);
                    let repairStep = JSON.parse(localStorage.getItem('repair_step'));
                    if (repairStep) {
                        window.location.href = '/Repair';
                    } else {
                        window.location.href = '/';
                    }
                }
            })
            .catch((error) => {
                setShowLoader(false);
                console.error('Error:', error);
                enqueueSnackbar('Network error', { variant: 'error' });
            });

        
    } else if (response.status === 'not_authorized') {
        
        console.log('user not authorized');
        
    } else {
        
        console.log('Facebook login fail:', response);
       
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
  });

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errMsg = {};
    
    errMsg.email = isStringEmpty(formData.email) ? 'Email is required' : '';
    if (!errMsg.email) {
        errMsg.email = !isEmailValid(formData.email) ? 'Email is not valid' : '';
    }

    errMsg.password = isStringEmpty(formData.password) ? 'Password is required' : '';

    setErrors({...errMsg});

    return Object.values(errMsg).every(item => item === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(validateForm());

    if (!captchaValue) {
      enqueueSnackbar('Please verify google reCaptcha',  {variant: 'error'})
      return;
    }

    if (!validateForm()) {
      enqueueSnackbar('Please fill in the form correctly',  {variant: 'error'})
      return;
    } 
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    setShowLoader(true)
    Api.post('/customer/login', data)
      .then((data) => {
        console.log('User login:', data);
        if (data.code !== 1000) {
          setShowLoader(false);
          recaptchaRef.current.reset();
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          enqueueSnackbar('User login successfully',  {variant: 'success'})
          Cookies.set('acc_token', data.data.access_token);
          let repairStep = JSON.parse(localStorage.getItem('repair_step'));
          if (repairStep) {
            window.location.href = '/Repair';
          } else {
            window.location.href = '/';
          }
          
        }
      })
      .catch((error) => {
        setShowLoader(false);
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  React.useEffect(() => {
    setShowSidebar(0)
    
  }, []);

  return (
    <GoogleOAuthProvider clientId={config.google_auth_key}>
    <Box component="main" sx={{ flexGrow: 1, mb:5}}>
    <Loader showLoader={showLoader} />  
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            // marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#EA3A13' }}>
            <LockOutlinedIcon />
          </Avatar>
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          <div>
          <ButtonGroup  aria-label="medium secondary button group">
            <Button  key="Customer" >Customer</Button>
            <GrayButton onClick={()=>{window.location.href=config.tech_host + '/technician' }} key="Technician">Technician</GrayButton>
          </ButtonGroup>
          </div>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              onChange={handleChange}
              error={errors.email ? true : false}
              helperText={errors.email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={handleChange}
              error={errors.password ? true : false}
              helperText={errors.password}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handlePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={ReCAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/ForgotPwd" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/SignUp" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
          <GoogleLogin
              text="Sign in with Google2"
              onSuccess={onGoogleSuccess}
              onError={onGoogleFailure}
              style={{ height: 45}}
              logo_alignment="left"
              shape="square"
          />
          {/* <GoogleLogin
            onSuccess={credentialResponse => {
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          /> */}
          <FacebookLogin
              appId={facebookAppId}
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              cssClass="my-facebook-button"
          />
        </div>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
    </Box>
    </GoogleOAuthProvider>
  );
}
